<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined>
        <v-card-title>
          <v-row class="d-flex justify-space-between">
            <v-col cols="6" md="2">Quarterly Plan</v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- Start Quarterly Plan section -->
          <v-container>
            <div v-if="plan">
              <v-row>
                <v-col class="font-weight-black text-h6" md="6" cols="12">
                  {{ plan.title }} (Start Date:
                  {{ formatDateUs(plan.startDate) }})
                </v-col>
                <v-col md="6" cols="12">
                  <router-link class="mx-1" to="#">
                    <v-btn @click="downloadPdf">Download Pdf</v-btn>
                  </router-link>
                  <router-link
                    v-if="
                      permissions.ninetyDayPlan &&
                        permissions.ninetyDayPlan.canEdit &&
                        plan.isActive
                    "
                    class="mx-1"
                    :to="`/customers/${customerId}/plans/${plan.id}/edit`"
                  >
                    <v-btn class="orange-bg white--text">Edit</v-btn>
                  </router-link>
                  <v-btn class="mx-1" @click="showDeletePlan = true">
                    Delete
                  </v-btn>
                  <router-link
                    v-if="
                      permissions.ninetyDayPlan &&
                        permissions.ninetyDayPlan.canCreate
                    "
                    class="mx-1"
                    :to="`/customers/${customerId}/plans/new`"
                  >
                    <v-btn class="orange-bg white--text">New</v-btn>
                  </router-link>
                </v-col>
                <!-- <v-col> </v-col> -->
              </v-row>
              <v-row>
                <v-col md="6" cols="12">
                  <v-progress-linear
                    color="blue"
                    :value="plan.progress"
                    height="25"
                  >
                    <template v-slot:default="{ value }">
                      <strong>{{ Math.ceil(value) }}%</strong>
                    </template></v-progress-linear
                  >
                </v-col>
                <v-col md="6" cols="12">
                  <span class="text-h6">
                    Quarterly Orange Walk is scheduled for
                    <span class="font-weight-bold">
                      {{ formatDateUs(plan.orangeWalkDate) }}
                    </span>
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="8">
                  <hr />
                  <h6>{{ month1Name }} Tasks</h6>
                  <div>
                    <table width="100%" class="mt-2">
                      <tr
                        v-for="(item, i) in plan.day30Tasks.$values"
                        :key="'tr30' + i"
                      >
                        <td width="80%">
                          {{ item.description }}
                        </td>
                        <td>
                          {{ getStatusName(item.status) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <h6>{{ month1Name }} Notes</h6>
                  <div>
                    <v-textarea readonly v-model="plan.day30" solo></v-textarea>
                  </div>
                  <hr />
                  <h6>{{ month2Name }} Tasks</h6>
                  <div>
                    <table width="100%" class="mt-2">
                      <tr
                        v-for="(item, i) in plan.day60Tasks.$values"
                        :key="'tr60' + i"
                      >
                        <td width="80%">
                          {{ item.description }}
                        </td>
                        <td>
                          {{ getStatusName(item.status) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <h6>{{ month2Name }} Notes</h6>
                  <div>
                    <v-textarea readonly v-model="plan.day60" solo></v-textarea>
                  </div>
                  <hr />
                  <h6>{{ month3Name }} Tasks</h6>
                  <div>
                    <table width="100%" class="mt-2">
                      <tr
                        v-for="(item, i) in plan.day90Tasks.$values"
                        :key="'tr90' + i"
                      >
                        <td width="80%">
                          {{ item.description }}
                        </td>
                        <td>
                          {{ getStatusName(item.status) }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <h6>{{ month3Name }} Notes</h6>
                  <div>
                    <v-textarea readonly v-model="plan.day90" solo></v-textarea>
                  </div>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      v-for="(item, i) in plan.photos.$values"
                      :key="'o' + i"
                    >
                      <div>
                        <a :href="item.fileUrl" target="_blank">
                          <v-img :src="item.fileUrl"></v-img>
                        </a>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
            <div v-else class="text-center">
              <div class="text-subtitle-1 text-center">No Quarterly Plan</div>
              <router-link
                :to="`/customers/${customerId}/plans/new`"
                v-if="
                  permissions.ninetyDayPlan &&
                    permissions.ninetyDayPlan.canCreate
                "
              >
                <v-btn class="orange-bg white--text">
                  Create Quarterly Plan
                </v-btn>
              </router-link>
            </div>
            <v-dialog v-model="showDeletePlan" persistent max-width="290">
              <v-card>
                <v-card-title></v-card-title>
                <v-card-text>
                  Are you sure you want to delete the plan? All related Orange
                  Reports will be deleted too.
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="!statusProcessing"
                    color="green darken-1"
                    text
                    @click="showDeletePlan = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="deletePlan()"
                    :loading="statusProcessing"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-container>
          <!-- End Quarterly Plan section -->
        </v-card-text></v-card
      >

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { DELETE, GET_QUERY, API_CUSTOMERS } from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    plan: {
      startDate: null,
      orangeWalkDate: null,
      formattedQuarterlyOrangeWalkDate: null,
      progress: 0,
      day30: "",
      day60: "",
      day90: "",
      photos: []
    },

    quarterlyOrangeWalkDateMenu: false,
    quarterlyOrangeWalkDate: null,
    formattedQuarterlyOrangeWalkDate: null,
    planId: 0,
    pageText: "",
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    permissions: {},
    baseUrl: `${process.env.VUE_APP_DASHBOARD_URL}`,
    month1Name: "30 Days",
    month2Name: "60 Days",
    month3Name: "90 Days",
    showDeletePlan: false,
    statusProcessing: false
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    },
    planId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
    // return {
    //   billingInfo: {
    //     subscription: {
    //       nextBillingDates: {
    //         required,
    //         minDate: value =>
    //           dateFormatter.convertToMilliseconds(value) >
    //           dateFormatter.convertToMilliseconds(new Date())
    //       }
    //     }
    //   }
    // };
  },
  watch: {
    "plan.orangeWalkDate": function() {
      this.formattedQuarterlyOrangeWalkDate = this.formatDate(
        this.quarterlyOrangeWalkDate
      );
    }
  },
  async mounted() {},
  async created() {
    await this.getComunityInfo();
    if (this.planId) await this.getNinetyDayPlan();
    await permissionsHelper.getPermissions().then(this.getPermissions);
  },
  methods: {
    async deletePlan() {
      this.statusProcessing = true;

      let self = this;
      let url = `${API_CUSTOMERS}/${this.customerId}/plans`;
      self.$store
        .dispatch(DELETE, {
          listName: url,
          id: self.planId
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Quarterly plan has been deleted";
            self.showDeletePlan = false;

            self.$snackbar.showMessage({
              content: self.snackbarContent,
              color: self.snackbarColor
            });

            self.$router.push({
              name: "NinetyDayPlans",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }

          self.showDeletePlan = false;
          self.statusProcessing = false;

          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });
    },
    getStatusName(status) {
      if (status === 0) return "Open";
      if (status === 1) return "In Process";
      if (status === 2) return "Completed";
    },
    urlToBase64(url) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = url;
        img.onload = () => {
          const canvas = document.createElement("canvas"),
            ctx = canvas.getContext("2d");
          canvas.height = img.height;
          canvas.width = img.width;
          ctx.drawImage(img, 0, 0);
          resolve(canvas.toDataURL("image/jpeg"));
        };
        img.onerror = error => reject(error);
      });
    },
    downloadPdf() {
      let pdfMake = require("pdfmake/build/pdfmake.js");
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }

      // let year = new Date(this.plan.startDate).getFullYear();
      // let month = new Date(this.plan.startDate).toLocaleString("en-us", {
      //   month: "long"
      // });

      let promisses = this.plan.photos.$values.map(photo => {
        return this.urlToBase64(photo.fileUrl);
      });

      let self = this;

      Promise.allSettled(promisses).then(function(res) {
        let images = res.map(res => {
          return {
            image: res.value, //this.dataUrl,//"sidebar", //photo.fileUrl,
            // style: "photos",
            width: 120
            //,            height: 60
          };
        });

        let images30Day = images.slice(0, 2);
        let images60Day = images.slice(2, 4);
        let images90Day = images.slice(4, 6);

        let day30Tasks =
          self.plan.day30Tasks.$values.length > 0
            ? self.plan.day30Tasks.$values.map(res => [
                res.description,
                self.getStatusName(res.status)
              ])
            : null;

        let day60Tasks =
          self.plan.day60Tasks.$values.length > 0
            ? self.plan.day60Tasks.$values.map(res => [
                res.description,
                self.getStatusName(res.status)
              ])
            : null;

        let day90Tasks =
          self.plan.day90Tasks.$values.length > 0
            ? self.plan.day90Tasks.$values.map(res => [
                res.description,
                self.getStatusName(res.status)
              ])
            : null;

        let docDefinition = {
          images: {
            ynLogo: self.baseUrl + "/media/logos/logo-light.png",
            sidebar: self.baseUrl + "/media/assets/aside-bg.jpg"
          },
          pageSize: "LETTER",
          pageMargins: [150, 40, 0, 40],
          background: function(currentPage, pageSize) {
            return [
              {
                canvas: [
                  {
                    type: "rect",
                    x: 0,
                    y: 0,
                    w: 150,
                    h: pageSize.height / 5,
                    color: "#E06521"
                  },
                  {
                    type: "rect",
                    x: 0,
                    y: pageSize.height / 5,
                    w: 150,
                    h: 5,
                    color: "#2DC1E4"
                  }
                ]
              },
              {
                image: "ynLogo",
                width: 120,
                absolutePosition: { x: 10, y: 15 }
              },
              {
                text: "Quarterly".toUpperCase(),
                absolutePosition: { x: 30, y: 67 },
                color: "white",
                fontSize: 20,
                bold: true,
                characterSpacing: -0.5
              },
              {
                text: "PLAN",
                absolutePosition: { x: 30, y: 85 },
                color: "black",
                fontSize: 20,
                bold: true,
                characterSpacing: 0.5
              },
              {
                text: self.plan.title.toUpperCase(),
                absolutePosition: { x: 30, y: 112 },
                color: "white",
                fontSize: 20,
                bold: true
              },
              // {
              //   text: year,
              //   absolutePosition: { x: 30, y: 130 },
              //   color: "black",
              //   fontSize: 20,
              //   bold: true
              // },
              {
                image: "sidebar",
                width: 150,
                height: pageSize.height * (4 / 5) - 5
              }
            ];
          },
          content: [
            {
              text: self.communityInfo.name.toUpperCase(),
              margin: [30, 0, 0, 15],
              style: {
                fontSize: 15,
                bold: true
              }
            },
            {
              stack: [
                {
                  canvas: [
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: 300,
                      h: 25,
                      color: "#E7E7E8" // light gray
                    },
                    {
                      type: "rect",
                      x: 0,
                      y: 0,
                      w: (300 / 90) * self.plan.progress, // <-- update progress here
                      h: 25,
                      color: "#97B73D" // green
                    }
                  ]
                },
                {
                  text: self.plan.progress + "%",
                  fontSize: 15,
                  relativePosition: { x: 150 - 20, y: -21 }
                }
              ],
              margin: [30, 0, 0, 30]
            },
            {
              table: {
                widths: [22, "*", "auto"],
                body: [
                  [
                    {}, // empty
                    {
                      stack: [
                        {
                          text: self.month1Name,
                          style: ["large", "green", "mb5"]
                        },
                        { text: "Tasks", style: ["medium", "green", "mb2"] },
                        {
                          table: {
                            widths: ["*", "auto"],
                            body: day30Tasks ? day30Tasks : [["", ""]]
                          }
                        },
                        { text: "Notes", style: ["medium", "green", "mb2"] },
                        { text: self.plan.day30 }
                      ],
                      margin: [0, 10]
                    },
                    {
                      text: "30",
                      style: ["extra-large", "white"],
                      alignment: "right",
                      relativePosition: { y: -20 }
                    }
                  ],
                  [
                    {},
                    {
                      columns: images30Day,
                      colSpan: 2,
                      columnGap: 10,
                      margin: [0, 10]
                    },
                    {}
                  ]
                ]
              },
              layout: "noBorders",
              style: { fillColor: "#E9E9E9" }
            },
            {
              table: {
                widths: [22, "*", "auto"],
                body: [
                  [
                    {}, // empty
                    {
                      stack: [
                        {
                          text: self.month2Name,
                          style: ["large", "orange", "mb5"]
                        },
                        { text: "Tasks", style: ["medium", "orange", "mb2"] },
                        {
                          table: {
                            widths: ["*", "auto"],
                            body: day60Tasks ? day60Tasks : [["", ""]]
                          }
                        },
                        { text: "Notes", style: ["medium", "orange", "mb2"] },
                        { text: self.plan.day60 }
                      ],
                      margin: [0, 10]
                    },
                    {
                      text: "60",
                      style: ["extra-large", "gray"],
                      alignment: "right",
                      relativePosition: { y: -20 }
                    }
                  ],
                  [
                    {},
                    {
                      columns: images60Day,
                      colSpan: 2,
                      columnGap: 10,
                      margin: [0, 10]
                    },
                    {}
                  ]
                ]
              },
              layout: "noBorders",
              style: { fillColor: "#ffffff" }
            },
            {
              table: {
                widths: [22, "*", "auto"],
                body: [
                  [
                    {}, // empty
                    {
                      stack: [
                        {
                          text: self.month3Name,
                          style: ["large", "black", "mb5"]
                        },
                        { text: "Tasks", style: ["medium", "black", "mb2"] },
                        {
                          table: {
                            widths: ["*", "auto"],
                            body: day90Tasks ? day90Tasks : [["", ""]]
                          }
                        },
                        { text: "Notes", style: ["medium", "black", "mb2"] },
                        { text: self.plan.day90 }
                      ],
                      margin: [0, 10]
                    },
                    {
                      text: "90",
                      style: ["extra-large", "white"],
                      alignment: "right",
                      relativePosition: { y: -20 }
                    }
                  ],
                  [
                    {},
                    {
                      columns: images90Day,
                      colSpan: 2,
                      columnGap: 10,
                      margin: [0, 10]
                    },
                    {}
                  ]
                ]
              },
              layout: "noBorders",
              style: { fillColor: "#E9E9E9" }
            }
          ],
          defaultStyle: {
            fontSize: 8,
            bold: false
          },
          styles: {
            large: { fontSize: 20, lineHeight: 1.3, bold: true },
            medium: { fontSize: 16, lineHeight: 1.2, bold: true },
            "extra-large": { fontSize: 90, bold: true },
            black: { color: "black" },
            gray: { color: "#E9E9E9" },
            green: { color: "#94b43f" },
            orange: { color: "#df6526" },
            white: { color: "white" },
            mt2: { margin: [0, 2, 0, 0] },
            mt5: { margin: [0, 5, 0, 0] },
            mt10: { margin: [0, 10, 0, 0] },
            mb5: { margin: [0, 0, 0, 5] },
            mb10: { margin: [0, 0, 0, 10] },
            my5: { margin: [0, 5, 0, 5] },
            my10: { margin: [0, 10, 0, 10] }
          }
        };
        pdfMake.createPdf(docDefinition).open();
      });
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    // changePlan() {
    //   this.plan = this.getActivePlan(this.planId);
    // },
    async getNinetyDayPlan() {
      let url = `${API_CUSTOMERS}/${this.customerId}/plans/current`;

      if (this.planId > 0)
        url = `${API_CUSTOMERS}/${this.customerId}/plans/${this.planId}`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: url
        })
        .then(response => {
          this.plan = {
            progress: 0,
            startDate: new Date().toISOString().substr(0, 10),
            orangeWalkDate: new Date().toISOString().substr(0, 10),
            day30: "",
            day60: "",
            day90: "",
            photos: []
          };
          if (response.status >= 200 || response.status <= 204) {
            this.plan = response.data;
            if (this.plan) {
              if (this.plan.title.indexOf("Q1") > -1) {
                this.month1Name = "January";
                this.month2Name = "February";
                this.month3Name = "March";
              } else if (this.plan.title.indexOf("Q2") > -1) {
                this.month1Name = "April";
                this.month2Name = "May";
                this.month3Name = "June";
              } else if (this.plan.title.indexOf("Q3") > -1) {
                this.month1Name = "July";
                this.month2Name = "August";
                this.month3Name = "September";
              } else if (this.plan.title.indexOf("Q4") > -1) {
                this.month1Name = "October";
                this.month2Name = "November";
                this.month3Name = "December";
              }

              this.populatePhotosObj(this.plan.photos.$values);

              this.quarterlyOrangeWalkDate = new Date(this.plan.orangeWalkDate)
                .toISOString()
                .substr(0, 10);
              this.formattedQuarterlyOrangeWalkDate = this.formatDate(
                this.quarterlyOrangeWalkDate
              );
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }
        });
    },
    populatePhotosObj(photos) {
      this.photosObj = photos.map(obj => {
        return { id: obj.id, url: obj.fileUrl, fileId: obj.id };
      });
      let max = Math.max(...this.photosObj.map(i => i.id));
      this.photosObj.push({ id: (max += 1), url: null });
    },
    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        { title: this.plan.title }
      ];
    },

    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        { title: this.plan.title }
      ]);
    },
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    formatDate(date) {
      return dateFormatter.formatEditDate(date);
    },
    formatDateUs(date) {
      return dateFormatter.formatDateUs(date);
      // return moment(date).format("MM/DD/YYYY");
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {
    archivedPlans() {
      let items = [];
      if (!this.communityInfo.ninetyDayPlans) return items;

      items = this.communityInfo.ninetyDayPlans.slice().sort((a, b) => {
        // return new Date(b.startDate) - new Date(a.startDate);
        return b.id - a.id;
      });
      return items;
    }
  }
};
</script>
